(function (cjs, an) {

var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.webFontTxtInst = {}; 
var loadedTypekitCount = 0;
var loadedGoogleCount = 0;
var gFontsUpdateCacheList = [];
var tFontsUpdateCacheList = [];
lib.ssMetadata = [];



lib.updateListCache = function (cacheList) {		
	for(var i = 0; i < cacheList.length; i++) {		
		if(cacheList[i].cacheCanvas)		
			cacheList[i].updateCache();		
	}		
};		

lib.addElementsToCache = function (textInst, cacheList) {		
	var cur = textInst;		
	while(cur != null && cur != exportRoot) {		
		if(cacheList.indexOf(cur) != -1)		
			break;		
		cur = cur.parent;		
	}		
	if(cur != exportRoot) {		
		var cur2 = textInst;		
		var index = cacheList.indexOf(cur);		
		while(cur2 != null && cur2 != cur) {		
			cacheList.splice(index, 0, cur2);		
			cur2 = cur2.parent;		
			index++;		
		}		
	}		
	else {		
		cur = textInst;		
		while(cur != null && cur != exportRoot) {		
			cacheList.push(cur);		
			cur = cur.parent;		
		}		
	}		
};		

lib.gfontAvailable = function(family, totalGoogleCount) {		
	lib.properties.webfonts[family] = true;		
	var txtInst = lib.webFontTxtInst && lib.webFontTxtInst[family] || [];		
	for(var f = 0; f < txtInst.length; ++f)		
		lib.addElementsToCache(txtInst[f], gFontsUpdateCacheList);		

	loadedGoogleCount++;		
	if(loadedGoogleCount == totalGoogleCount) {		
		lib.updateListCache(gFontsUpdateCacheList);		
	}		
};		

lib.tfontAvailable = function(family, totalTypekitCount) {		
	lib.properties.webfonts[family] = true;		
	var txtInst = lib.webFontTxtInst && lib.webFontTxtInst[family] || [];		
	for(var f = 0; f < txtInst.length; ++f)		
		lib.addElementsToCache(txtInst[f], tFontsUpdateCacheList);		

	loadedTypekitCount++;		
	if(loadedTypekitCount == totalTypekitCount) {		
		lib.updateListCache(tFontsUpdateCacheList);		
	}		
};
// symbols:



(lib.HD_background_02 = function() {
	this.initialize(img.HD_background_02);
}).prototype = p = new cjs.Bitmap();
p.nominalBounds = new cjs.Rectangle(0,0,1962,1104);// helper functions:

function mc_symbol_clone() {
	var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop));
	clone.gotoAndStop(this.currentFrame);
	clone.paused = this.paused;
	clone.framerate = this.framerate;
	return clone;
}

function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
	var prototype = cjs.extend(symbol, cjs.MovieClip);
	prototype.clone = mc_symbol_clone;
	prototype.nominalBounds = nominalBounds;
	prototype.frameBounds = frameBounds;
	return prototype;
	}


(lib.TextPlaceholderBackgroundAlternate = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Laag 1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#00FF00").s().p("AnzH0IAAvnIPnAAIAAPng");
	this.shape.setTransform(50,50);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

}).prototype = getMCSymbolPrototype(lib.TextPlaceholderBackgroundAlternate, new cjs.Rectangle(0,0,100,100), null);


(lib.TextPlaceholderBackground = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Laag 1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#FF00FF").s().p("AnzH0IAAvnIPnAAIAAPng");
	this.shape.setTransform(50,50);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

}).prototype = getMCSymbolPrototype(lib.TextPlaceholderBackground, new cjs.Rectangle(0,0,100,100), null);


(lib.Marker = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

}).prototype = getMCSymbolPrototype(lib.Marker, null, null);


(lib.TextPlaceholderBackground_1 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Laag 1
	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#FF00FF").s().p("AnzH0IAAvnIPnAAIAAPng");
	this.shape_1.setTransform(50,50);

	this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(1));

}).prototype = getMCSymbolPrototype(lib.TextPlaceholderBackground_1, new cjs.Rectangle(0,0,100,100), null);


(lib.Marker_1 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

}).prototype = getMCSymbolPrototype(lib.Marker_1, null, null);


(lib.btnScrollUp = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer 1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#592230").s().p("AiLBcICDi3ICUC3g");
	this.shape.setTransform(20.3,13.5);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f().s("#592230").ss(4).p("ACgfhIk/AAMAAAg/BIE/AAg");
	this.shape_1.setTransform(19.7,15.5,1.232,0.077);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#8B504F").s().p("AiffhMAAAg/BIE/AAMAAAA/Bg");
	this.shape_2.setTransform(19.7,15.5,1.232,0.077);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.btnScrollUp, new cjs.Rectangle(-2,-2,43.4,35), null);


(lib.btnScrollDown = function(mode,startPosition,loop) {
if (loop == null) { loop = false; }	this.initialize(mode,startPosition,loop,{});

	// Layer 1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#592230").s().p("Ahwg+IDhAAIh3B9g");
	this.shape.setTransform(18.3,15.3,1.067,1.213);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f().s("#592230").ss(4).p("ACgfhIk/AAMAAAg/BIE/AAg");
	this.shape_1.setTransform(19.7,13.5,1.232,0.067,180);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#8B504F").s().p("AiffhMAAAg/BIE/AAMAAAA/Bg");
	this.shape_2.setTransform(19.7,13.5,1.232,0.067,180);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_2},{t:this.shape_1},{t:this.shape}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.btnScrollDown, new cjs.Rectangle(-2,-2,43.4,31), null);


(lib.TextPlaceholderBackground_2 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Laag 1
	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#FF00FF").s().p("AnzH0IAAvnIPnAAIAAPng");
	this.shape_2.setTransform(50,50);

	this.timeline.addTween(cjs.Tween.get(this.shape_2).wait(1));

}).prototype = getMCSymbolPrototype(lib.TextPlaceholderBackground_2, new cjs.Rectangle(0,0,100,100), null);


(lib.mobile = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer 1
	this.shape = new cjs.Shape();
	this.shape.graphics.rf(["#320B1F","#190412"],[0.004,1],0,0,0,0,0,6.2).s().p("AgoBOIAAibIBRAGIgTCVg");
	this.shape.setTransform(37.5,6.5,0.839,0.839);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.rf(["#320B1F","#190412"],[0.004,1],0,0,0,0,0,30.1).s().p("AhQE/IiZp9IHTgkIgbLFg");
	this.shape_1.setTransform(19.6,42.1,0.839,0.839);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.mobile, new cjs.Rectangle(0,0,41,72), null);


(lib.CloseButton = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Laag 1
	this.shape = new cjs.Shape();
	this.shape.graphics.f().s("#FF873A").ss(3,1,1).p("Ag6hBIA+A+IBChCAAEgDIA9A9AhFBGIBJhJ");
	this.shape.setTransform(6.7,7.5);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	// Laag 2
	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#51232D").s().p("AhbBcIAAi3IC3AAIAAC3g");
	this.shape_1.setTransform(6.8,7.8);

	this.timeline.addTween(cjs.Tween.get(this.shape_1).wait(1));

}).prototype = getMCSymbolPrototype(lib.CloseButton, new cjs.Rectangle(-2.4,-1.4,18.3,18.3), null);


(lib.BG2 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer 1
	this.instance = new lib.HD_background_02();
	this.instance.parent = this;
	this.instance.setTransform(0,0,0.979,0.978);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = getMCSymbolPrototype(lib.BG2, new cjs.Rectangle(0,0,1920,1080), null);


(lib.TextPlaceholder = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer 1
	this.instance = new lib.TextPlaceholderBackground_2();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = getMCSymbolPrototype(lib.TextPlaceholder, new cjs.Rectangle(0,0,100,100), null);


(lib.PlaceholderAlternateColor = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer 1
	this.instance = new lib.TextPlaceholderBackgroundAlternate();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = getMCSymbolPrototype(lib.PlaceholderAlternateColor, new cjs.Rectangle(0,0,100,100), null);


(lib.Placeholder = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer 1
	this.instance = new lib.TextPlaceholderBackground();
	this.instance.parent = this;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

}).prototype = getMCSymbolPrototype(lib.Placeholder, new cjs.Rectangle(0,0,100,100), null);


(lib.Placeholder_1 = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer 1
	this.instance_1 = new lib.TextPlaceholderBackground_1();
	this.instance_1.parent = this;
	this.instance_1.setTransform(0,0,0.901,1);

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

}).prototype = getMCSymbolPrototype(lib.Placeholder_1, new cjs.Rectangle(0,0,90.1,100), null);


(lib.scrollBar = function(mode,startPosition,loop) {
if (loop == null) { loop = false; }	this.initialize(mode,startPosition,loop,{});

	// Layer 1
	this.bottom = new lib.Marker_1();
	this.bottom.parent = this;
	this.bottom.setTransform(10.6,440);

	this.top = new lib.Marker_1();
	this.top.parent = this;
	this.top.setTransform(11.3,0);

	this.shape = new cjs.Shape();
	this.shape.graphics.f().s("rgba(89,34,48,0)").ss(0.1).p("EACgAiYIk/AAMAAAhEvIE/AAg");
	this.shape.setTransform(16,220);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#8B504F").s().p("EgCfAiYMAAAhEvIE/AAMAAABEvg");
	this.shape_1.setTransform(16,220);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape},{t:this.top},{t:this.bottom}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.scrollBar, new cjs.Rectangle(-1,-1,34,442), null);


(lib.Scrollbar = function(mode,startPosition,loop) {
if (loop == null) { loop = false; }	this.initialize(mode,startPosition,loop,{});

	// Layer 1
	this.maximum = new lib.Marker_1();
	this.maximum.parent = this;
	this.maximum.setTransform(20.1,343.5,1,1,0,0,0,0.1,0);

	this.minimum = new lib.Marker_1();
	this.minimum.parent = this;
	this.minimum.setTransform(20,45);

	this.indicator = new lib.scrollBar();
	this.indicator.parent = this;
	this.indicator.setTransform(20,88.2,0.813,0.386,0,0,0,15.9,0.3);

	this.down = new lib.btnScrollDown();
	this.down.parent = this;
	this.down.setTransform(17.9,360.9,1,1.611,0,0,0,17.9,10.8);
	new cjs.ButtonHelper(this.down, 0, 1, 1);

	this.up = new lib.btnScrollUp();
	this.up.parent = this;
	this.up.setTransform(19.7,21,1,1.338,0,0,0,19.7,15.5);
	new cjs.ButtonHelper(this.up, 0, 1, 1);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.up},{t:this.down},{t:this.indicator},{t:this.minimum},{t:this.maximum}]}).wait(1));

	// Layer 2
	this.shape = new cjs.Shape();
	this.shape.graphics.f().s("#592230").ss(5).p("Ai3C+IAAliIGIAA");
	this.shape.setTransform(18.5,16.7);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#592230").s().p("AjEYDMAAAgwFIABAAIGIAAMAAAAwFg");
	this.shape_1.setTransform(19.7,189.6);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.Scrollbar, new cjs.Rectangle(-2.6,-2.4,44.7,392.7), null);


(lib.LongButton = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{Over:1,"Over-NoAnim":6,Up:7,"Up-NoAnim":11,Down:12,Hit:13});

	// timeline functions:
	this.frame_0 = function() {
		this.stop ();
	}
	this.frame_6 = function() {
		this.stop ();
	}
	this.frame_11 = function() {
		this.stop ();
	}
	this.frame_12 = function() {
		this.stop ();
	}
	this.frame_13 = function() {
		this.stop ();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(6).call(this.frame_6).wait(5).call(this.frame_11).wait(1).call(this.frame_12).wait(1).call(this.frame_13).wait(1));

	// TextLabel
	this.text = new cjs.Text("Text", "16px 'Arial'", "#FF873A");
	this.text.name = "text";
	this.text.textAlign = "center";
	this.text.lineHeight = 18;
	this.text.lineWidth = 232;
	this.text.parent = this;
	this.text.setTransform(134.5,15.6);

	this.text_1 = new lib.TextPlaceholder();
	this.text_1.parent = this;
	this.text_1.setTransform(15.5,12,1.741,0.542,0,0,0,0.5,-0.2);
	this.text_1._off = true;

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.text}]}).to({state:[{t:this.text_1}]},1).to({state:[{t:this.text_1}]},5).to({state:[{t:this.text_1}]},1).to({state:[{t:this.text_1}]},4).to({state:[{t:this.text_1}]},1).to({state:[{t:this.text_1}]},1).wait(1));
	this.timeline.addTween(cjs.Tween.get(this.text_1).wait(1).to({_off:false},0).to({regX:0.4,regY:-0.3,scaleY:0.56,rotation:6,x:14,y:1.7},5).wait(1).to({regX:0.5,regY:-0.1,scaleY:0.54,rotation:6.2,x:16.8,y:1.8},0).to({regY:-0.2,rotation:0,x:15.5,y:12},4).wait(3));

	// Layer 3
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#0F0609").s().p("Ay8CbIiRk5MAqbgAGIjpFJg");
	this.shape.setTransform(140.2,23.8);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#0F0609").s().p("AxBE2IiCp1MAmHgAKIjSKTg");
	this.shape_1.setTransform(103.4,39.1,0.821,0.821);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#0F0609").s().p("AuwDkIhboaMAgXABEIjGIog");
	this.shape_2.setTransform(104.2,39.3);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#0F0609").s().p("AvXDUIhPorMAhNAB8IjbIzg");
	this.shape_3.setTransform(104.8,39);

	this.shape_4 = new cjs.Shape();
	this.shape_4.graphics.f("#0F0609").s().p("AvzDHIhGo3MAhzACnIjpI5g");
	this.shape_4.setTransform(105.2,38.9);

	this.shape_5 = new cjs.Shape();
	this.shape_5.graphics.f("#0F0609").s().p("AwEDBIhBo/MAiLADAIjzI9g");
	this.shape_5.setTransform(105.5,38.7);

	this.shape_6 = new cjs.Shape();
	this.shape_6.graphics.f("#0F0507").s().p("AvnDNIhJoyMAhhACUIjiI3g");
	this.shape_6.setTransform(105,38.9);

	this.shape_7 = new cjs.Shape();
	this.shape_7.graphics.f("#0F0305").s().p("AvEDcIhUojMAgxABhIjQIug");
	this.shape_7.setTransform(104.5,39.2);

	this.shape_8 = new cjs.Shape();
	this.shape_8.graphics.f("#0F0202").s().p("AugDsIhgoUMAgBAAsIi+Ilg");
	this.shape_8.setTransform(103.9,39.4);

	this.shape_9 = new cjs.Shape();
	this.shape_9.graphics.f("#0F0000").s().p("At9D/IhroFIfRgIIisIdg");
	this.shape_9.setTransform(103.4,39.2);

	this.shape_10 = new cjs.Shape();
	this.shape_10.graphics.f("#990000").s().p("AxBE2IiCp1MAmHgAKIjSKTg");
	this.shape_10.setTransform(103.4,39.1,0.821,0.821);

	this.shape_11 = new cjs.Shape();
	this.shape_11.graphics.f("#0F51B8").s().p("AxBE2IiCp1MAmHgAKIjSKTg");
	this.shape_11.setTransform(103.4,39.1,0.821,0.821);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[{t:this.shape_1,p:{scaleX:0.821,scaleY:0.821,rotation:0,x:103.4,y:39.1}}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_5}]},1).to({state:[{t:this.shape_1,p:{scaleX:0.903,scaleY:0.903,rotation:5.4,x:102.8,y:39.2}}]},1).to({state:[{t:this.shape_1,p:{scaleX:0.903,scaleY:0.903,rotation:5.4,x:102.8,y:39.2}}]},1).to({state:[{t:this.shape_6}]},1).to({state:[{t:this.shape_7}]},1).to({state:[{t:this.shape_8}]},1).to({state:[{t:this.shape_9}]},1).to({state:[{t:this.shape_10}]},1).to({state:[{t:this.shape_11}]},1).wait(1));

	// Layer 2
	this.shape_12 = new cjs.Shape();
	this.shape_12.graphics.f().s("#0F0609").ss(2).p("ATzluMgnmAAPIB4LPMAi1gAUg");
	this.shape_12.setTransform(0,6.4,1.113,0.499,0,0,0,-126.8,-35.2);

	this.shape_13 = new cjs.Shape();
	this.shape_13.graphics.f().s("#0A6006").ss(2).p("Aw1kaMAhqgAtIiUJiI9nAtg");
	this.shape_13.setTransform(103.5,39.3,1,1,0,0,0,0.1,0);

	this.shape_14 = new cjs.Shape();
	this.shape_14.graphics.f().s("#05A503").ss(2).p("AxSkVMAikgBGIiRJzI+bBEg");
	this.shape_14.setTransform(103.1,39.2,1,1,0,0,0,0.1,0);

	this.shape_15 = new cjs.Shape();
	this.shape_15.graphics.f().s("#02D701").ss(2).p("AxmkSMAjNgBXIiPKAI+/BTg");
	this.shape_15.setTransform(102.7,39.2,1,1,0,0,0,0.1,0);

	this.shape_16 = new cjs.Shape();
	this.shape_16.graphics.f().s("#01F500").ss(2).p("AxzkPMAjngBjIiPKJI/UBcg");
	this.shape_16.setTransform(102.5,39.1,1,1,0,0,0,0.1,0);

	this.shape_17 = new cjs.Shape();
	this.shape_17.graphics.f().s("#00FF00").ss(2).p("ATzluMgnmAAPIB4LPMAi1gAUg");
	this.shape_17.setTransform(103.4,39.4,0.903,0.903,-2.2,0,0,-0.2,0);

	this.shape_18 = new cjs.Shape();
	this.shape_18.graphics.f().s("#0FFF00").ss(2).p("ATzluMgnmAAPIB4LPMAi1gAUg");
	this.shape_18.setTransform(103.4,39.4,0.903,0.903,-2.2,0,0,-0.2,0);

	this.shape_19 = new cjs.Shape();
	this.shape_19.graphics.f().s("#0FC102").ss(2).p("AxdkTMAi7gBQIiRJ7I+uBMg");
	this.shape_19.setTransform(102.9,39.2,1,1,0,0,0,0.1,0);

	this.shape_20 = new cjs.Shape();
	this.shape_20.graphics.f().s("#0F8305").ss(2).p("AxEkYMAiIgA5IiTJqI+BA5g");
	this.shape_20.setTransform(103.3,39.3,1,1,0,0,0,0.1,0);

	this.shape_21 = new cjs.Shape();
	this.shape_21.graphics.f().s("#0F4407").ss(2).p("AwpkcMAhTgAiIiWJaI9SAkg");
	this.shape_21.setTransform(103.7,39.3,1,1,0,0,0,0.1,0);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_12}]}).to({state:[{t:this.shape_12}]},1).to({state:[{t:this.shape_13}]},1).to({state:[{t:this.shape_14}]},1).to({state:[{t:this.shape_15}]},1).to({state:[{t:this.shape_16}]},1).to({state:[{t:this.shape_17}]},1).to({state:[{t:this.shape_18}]},1).to({state:[{t:this.shape_19}]},1).to({state:[{t:this.shape_20}]},1).to({state:[{t:this.shape_21}]},1).to({state:[{t:this.shape_12}]},1).to({state:[{t:this.shape_12}]},1).to({state:[{t:this.shape_12}]},1).wait(1));
	this.timeline.addTween(cjs.Tween.get(this.shape_12).wait(1).to({regX:-0.2,regY:0,scaleX:0.82,scaleY:0.82,x:103.9,y:39.4},0).to({_off:true},1).wait(9).to({_off:false},0).wait(3));

	// Layer 1
	this.shape_22 = new cjs.Shape();
	this.shape_22.graphics.f("#592230").s().p("A0sFHIAysmMAonACnIloMYg");
	this.shape_22.setTransform(148.6,23.9,1.113,0.499);

	this.shape_23 = new cjs.Shape();
	this.shape_23.graphics.f("#592230").s().p("AxrECIA5qsMAieAC6IlAKbg");
	this.shape_23.setTransform(109.5,39.1);

	this.shape_24 = new cjs.Shape();
	this.shape_24.graphics.f("#592230").s().p("AyND6IBEq+MAjXADgIlSKpg");
	this.shape_24.setTransform(109.6,38.9);

	this.shape_25 = new cjs.Shape();
	this.shape_25.graphics.f("#592230").s().p("AymD0IBNrKMAkAAD7IlgKyg");
	this.shape_25.setTransform(109.5,38.8);

	this.shape_26 = new cjs.Shape();
	this.shape_26.graphics.f("#592230").s().p("Ay0DxIBRrSMAkYAEMIlnK3g");
	this.shape_26.setTransform(109.5,38.7);

	this.shape_27 = new cjs.Shape();
	this.shape_27.graphics.f("#592230").s().p("AyaD3IBIrFMAjuADvIlaKug");
	this.shape_27.setTransform(109.6,38.9);

	this.shape_28 = new cjs.Shape();
	this.shape_28.graphics.f("#592230").s().p("Ax8D+IA+q1MAi7ADNIlJKig");
	this.shape_28.setTransform(109.6,39.1);

	this.shape_29 = new cjs.Shape();
	this.shape_29.graphics.f("#592230").s().p("AxdEFIAzqlMAiIACsIk4KVg");
	this.shape_29.setTransform(109.6,39.2);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_22}]}).to({state:[{t:this.shape_22}]},1).to({state:[{t:this.shape_23}]},1).to({state:[{t:this.shape_24}]},1).to({state:[{t:this.shape_25}]},1).to({state:[{t:this.shape_26}]},1).to({state:[{t:this.shape_22}]},1).to({state:[{t:this.shape_22}]},1).to({state:[{t:this.shape_27}]},1).to({state:[{t:this.shape_28}]},1).to({state:[{t:this.shape_29}]},1).to({state:[{t:this.shape_22}]},1).to({state:[{t:this.shape_22}]},1).to({state:[{t:this.shape_22}]},1).wait(1));
	this.timeline.addTween(cjs.Tween.get(this.shape_22).wait(1).to({scaleX:0.82,scaleY:0.82,x:109.5,y:39.4},0).to({_off:true},1).wait(4).to({_off:false,scaleX:0.9,scaleY:0.9,rotation:3,x:109.6},0).wait(1).to({_off:true},1).wait(3).to({_off:false,scaleX:0.82,scaleY:0.82,rotation:0,x:109.5},0).wait(3));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-1.4,0,297.5,47.9);


(lib.Button = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{"Over":1,"Over-NoAnim":6,"Up":7,"Up-NoAnim":11,"Down":12,"Hit":13});

	// timeline functions:
	this.frame_0 = function() {
		this.stop ();
	}
	this.frame_6 = function() {
		this.stop ();
	}
	this.frame_11 = function() {
		this.stop ();
	}
	this.frame_12 = function() {
		this.stop ();
	}
	this.frame_13 = function() {
		this.stop ();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(6).call(this.frame_6).wait(5).call(this.frame_11).wait(1).call(this.frame_12).wait(1).call(this.frame_13).wait(1));

	// TextLabel
	this.text = new cjs.Text("Text", "16px 'Arial'", "#FF873A");
	this.text.name = "text";
	this.text.textAlign = "center";
	this.text.lineHeight = 18;
	this.text.lineWidth = 118;
	this.text.parent = this;
	this.text.setTransform(77.2,15.6);

	this.text_1 = new lib.TextPlaceholder();
	this.text_1.parent = this;
	this.text_1.setTransform(15.5,12,1.741,0.542,0,0,0,0.5,-0.2);
	this.text_1._off = true;

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.text}]}).to({state:[{t:this.text_1}]},1).to({state:[{t:this.text_1}]},5).to({state:[{t:this.text_1}]},1).to({state:[{t:this.text_1}]},4).to({state:[{t:this.text_1}]},1).to({state:[{t:this.text_1}]},1).wait(1));
	this.timeline.addTween(cjs.Tween.get(this.text_1).wait(1).to({_off:false},0).to({regX:0.4,regY:-0.3,scaleY:0.56,rotation:6,x:14,y:1.7},5).wait(1).to({regX:0.5,regY:-0.1,scaleY:0.54,rotation:6.2,x:16.8,y:1.8},0).to({regY:-0.2,rotation:0,x:15.5,y:12},4).wait(3));

	// Layer 3
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#0F0609").s().p("A42D/Ii+oFMA3pgAIIkyIdg");
	this.shape.setTransform(76.9,23.8,0.418,0.608);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#0F0609").s().p("AxBE2IiCp1MAmHgAKIjSKTg");
	this.shape_1.setTransform(103.4,39.1,0.821,0.821);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#0F0609").s().p("AuwDkIhboaMAgXABEIjGIog");
	this.shape_2.setTransform(104.2,39.3);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#0F0609").s().p("AvXDUIhPorMAhNAB8IjbIzg");
	this.shape_3.setTransform(104.8,39);

	this.shape_4 = new cjs.Shape();
	this.shape_4.graphics.f("#0F0609").s().p("AvzDHIhGo3MAhzACnIjpI5g");
	this.shape_4.setTransform(105.2,38.9);

	this.shape_5 = new cjs.Shape();
	this.shape_5.graphics.f("#0F0609").s().p("AwEDBIhBo/MAiLADAIjzI9g");
	this.shape_5.setTransform(105.5,38.7);

	this.shape_6 = new cjs.Shape();
	this.shape_6.graphics.f("#0F0507").s().p("AvnDNIhJoyMAhhACUIjiI3g");
	this.shape_6.setTransform(105,38.9);

	this.shape_7 = new cjs.Shape();
	this.shape_7.graphics.f("#0F0305").s().p("AvEDcIhUojMAgxABhIjQIug");
	this.shape_7.setTransform(104.5,39.2);

	this.shape_8 = new cjs.Shape();
	this.shape_8.graphics.f("#0F0202").s().p("AugDsIhgoUMAgBAAsIi+Ilg");
	this.shape_8.setTransform(103.9,39.4);

	this.shape_9 = new cjs.Shape();
	this.shape_9.graphics.f("#0F0000").s().p("At9D/IhroFIfRgIIisIdg");
	this.shape_9.setTransform(103.4,39.2);

	this.shape_10 = new cjs.Shape();
	this.shape_10.graphics.f("#990000").s().p("AxBE2IiCp1MAmHgAKIjSKTg");
	this.shape_10.setTransform(103.4,39.1,0.821,0.821);

	this.shape_11 = new cjs.Shape();
	this.shape_11.graphics.f("#0F51B8").s().p("AxBE2IiCp1MAmHgAKIjSKTg");
	this.shape_11.setTransform(103.4,39.1,0.821,0.821);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[{t:this.shape_1,p:{scaleX:0.821,scaleY:0.821,rotation:0,x:103.4,y:39.1}}]},1).to({state:[{t:this.shape_2}]},1).to({state:[{t:this.shape_3}]},1).to({state:[{t:this.shape_4}]},1).to({state:[{t:this.shape_5}]},1).to({state:[{t:this.shape_1,p:{scaleX:0.903,scaleY:0.903,rotation:5.4,x:102.8,y:39.2}}]},1).to({state:[{t:this.shape_1,p:{scaleX:0.903,scaleY:0.903,rotation:5.4,x:102.8,y:39.2}}]},1).to({state:[{t:this.shape_6}]},1).to({state:[{t:this.shape_7}]},1).to({state:[{t:this.shape_8}]},1).to({state:[{t:this.shape_9}]},1).to({state:[{t:this.shape_10}]},1).to({state:[{t:this.shape_11}]},1).wait(1));

	// Layer 2
	this.shape_12 = new cjs.Shape();
	this.shape_12.graphics.f().s("#0F0609").ss(2).p("ATzluMgnmAAPIB4LPMAi1gAUg");
	this.shape_12.setTransform(77.3,23.9,0.611,0.499,0,0,0,-0.2,0);

	this.shape_13 = new cjs.Shape();
	this.shape_13.graphics.f().s("#0A6006").ss(2).p("Aw1kaMAhqgAtIiUJiI9nAtg");
	this.shape_13.setTransform(103.5,39.3,1,1,0,0,0,0.1,0);

	this.shape_14 = new cjs.Shape();
	this.shape_14.graphics.f().s("#05A503").ss(2).p("AxSkVMAikgBGIiRJzI+bBEg");
	this.shape_14.setTransform(103.1,39.2,1,1,0,0,0,0.1,0);

	this.shape_15 = new cjs.Shape();
	this.shape_15.graphics.f().s("#02D701").ss(2).p("AxmkSMAjNgBXIiPKAI+/BTg");
	this.shape_15.setTransform(102.7,39.2,1,1,0,0,0,0.1,0);

	this.shape_16 = new cjs.Shape();
	this.shape_16.graphics.f().s("#01F500").ss(2).p("AxzkPMAjngBjIiPKJI/UBcg");
	this.shape_16.setTransform(102.5,39.1,1,1,0,0,0,0.1,0);

	this.shape_17 = new cjs.Shape();
	this.shape_17.graphics.f().s("#00FF00").ss(2).p("ATzluMgnmAAPIB4LPMAi1gAUg");
	this.shape_17.setTransform(103.4,39.4,0.903,0.903,-2.2,0,0,-0.2,0);

	this.shape_18 = new cjs.Shape();
	this.shape_18.graphics.f().s("#0FFF00").ss(2).p("ATzluMgnmAAPIB4LPMAi1gAUg");
	this.shape_18.setTransform(103.4,39.4,0.903,0.903,-2.2,0,0,-0.2,0);

	this.shape_19 = new cjs.Shape();
	this.shape_19.graphics.f().s("#0FC102").ss(2).p("AxdkTMAi7gBQIiRJ7I+uBMg");
	this.shape_19.setTransform(102.9,39.2,1,1,0,0,0,0.1,0);

	this.shape_20 = new cjs.Shape();
	this.shape_20.graphics.f().s("#0F8305").ss(2).p("AxEkYMAiIgA5IiTJqI+BA5g");
	this.shape_20.setTransform(103.3,39.3,1,1,0,0,0,0.1,0);

	this.shape_21 = new cjs.Shape();
	this.shape_21.graphics.f().s("#0F4407").ss(2).p("AwpkcMAhTgAiIiWJaI9SAkg");
	this.shape_21.setTransform(103.7,39.3,1,1,0,0,0,0.1,0);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_12}]}).to({state:[{t:this.shape_12}]},1).to({state:[{t:this.shape_13}]},1).to({state:[{t:this.shape_14}]},1).to({state:[{t:this.shape_15}]},1).to({state:[{t:this.shape_16}]},1).to({state:[{t:this.shape_17}]},1).to({state:[{t:this.shape_18}]},1).to({state:[{t:this.shape_19}]},1).to({state:[{t:this.shape_20}]},1).to({state:[{t:this.shape_21}]},1).to({state:[{t:this.shape_12}]},1).to({state:[{t:this.shape_12}]},1).to({state:[{t:this.shape_12}]},1).wait(1));
	this.timeline.addTween(cjs.Tween.get(this.shape_12).wait(1).to({scaleX:0.82,scaleY:0.82,x:103.9,y:39.4},0).to({_off:true},1).wait(9).to({_off:false},0).wait(3));

	// Layer 1
	this.shape_22 = new cjs.Shape();
	this.shape_22.graphics.f("#592230").s().p("A0sFHIAysmMAonACnIloMYg");
	this.shape_22.setTransform(81.5,23.9,0.611,0.499);

	this.shape_23 = new cjs.Shape();
	this.shape_23.graphics.f("#592230").s().p("AxrECIA5qsMAieAC6IlAKbg");
	this.shape_23.setTransform(109.5,39.1);

	this.shape_24 = new cjs.Shape();
	this.shape_24.graphics.f("#592230").s().p("AyND6IBEq+MAjXADgIlSKpg");
	this.shape_24.setTransform(109.6,38.9);

	this.shape_25 = new cjs.Shape();
	this.shape_25.graphics.f("#592230").s().p("AymD0IBNrKMAkAAD7IlgKyg");
	this.shape_25.setTransform(109.5,38.8);

	this.shape_26 = new cjs.Shape();
	this.shape_26.graphics.f("#592230").s().p("Ay0DxIBRrSMAkYAEMIlnK3g");
	this.shape_26.setTransform(109.5,38.7);

	this.shape_27 = new cjs.Shape();
	this.shape_27.graphics.f("#592230").s().p("AyaD3IBIrFMAjuADvIlaKug");
	this.shape_27.setTransform(109.6,38.9);

	this.shape_28 = new cjs.Shape();
	this.shape_28.graphics.f("#592230").s().p("Ax8D+IA+q1MAi7ADNIlJKig");
	this.shape_28.setTransform(109.6,39.1);

	this.shape_29 = new cjs.Shape();
	this.shape_29.graphics.f("#592230").s().p("AxdEFIAzqlMAiIACsIk4KVg");
	this.shape_29.setTransform(109.6,39.2);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_22}]}).to({state:[{t:this.shape_22}]},1).to({state:[{t:this.shape_23}]},1).to({state:[{t:this.shape_24}]},1).to({state:[{t:this.shape_25}]},1).to({state:[{t:this.shape_26}]},1).to({state:[{t:this.shape_22}]},1).to({state:[{t:this.shape_22}]},1).to({state:[{t:this.shape_27}]},1).to({state:[{t:this.shape_28}]},1).to({state:[{t:this.shape_29}]},1).to({state:[{t:this.shape_22}]},1).to({state:[{t:this.shape_22}]},1).to({state:[{t:this.shape_22}]},1).wait(1));
	this.timeline.addTween(cjs.Tween.get(this.shape_22).wait(1).to({scaleX:0.82,scaleY:0.82,x:109.5,y:39.4},0).to({_off:true},1).wait(4).to({_off:false,scaleX:0.9,scaleY:0.9,rotation:3,x:109.6},0).wait(1).to({_off:true},1).wait(3).to({_off:false,scaleX:0.82,scaleY:0.82,rotation:0,x:109.5},0).wait(3));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-1.2,0,163.6,47.9);


(lib.UserModel = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Laag 1
	this.boundary = new lib.Marker();
	this.boundary.parent = this;
	this.boundary.setTransform(82.7,101.3);

	this.picture = new lib.Placeholder();
	this.picture.parent = this;
	this.picture.setTransform(0,0,0.75,0.75);

	this.name = new cjs.Text("User", "15px 'Arial'", "#FFFFFF");
	this.name.name = "name";
	this.name.textAlign = "center";
	this.name.lineHeight = 19;
	this.name.lineWidth = 71;
	this.name.alpha = 0.49803922;
	this.name.parent = this;
	this.name.setTransform(37.5,79.4);

	this.shape = new cjs.Shape();
	this.shape.graphics.f("rgba(0,0,0,0.498)").s().p("Al2F3IAArtILtAAIAALtg");
	this.shape.setTransform(37.5,37.5);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape},{t:this.name},{t:this.picture},{t:this.boundary}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.UserModel, new cjs.Rectangle(0,0,75,99.4), null);


(lib.PortalViewNoInactive = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer 2
	this.text = new cjs.Text("", "16px 'MyriadPro-Regular'");
	this.text.lineHeight = 18;
	this.text.lineWidth = 100;
	this.text.alpha = 0.97647059;
	this.text.parent = this;
	this.text.setTransform(902,244.6);

	this.back = new lib.Button();
	this.back.parent = this;
	this.back.setTransform(279,410.9,1,1,0,0,0,80.6,20.7);

	this.start = new lib.Button();
	this.start.parent = this;
	this.start.setTransform(601.6,410.9,1,1,0,0,0,80.6,20.7);

	this.text_1 = new cjs.Text("Active players", "23px 'Arial'", "#FF873A");
	this.text_1.lineHeight = 26;
	this.text_1.lineWidth = 202;
	this.text_1.parent = this;
	this.text_1.setTransform(127,152.6);

	this.playercount = new cjs.Text("0/8", "23px 'Arial'", "#FF873A");
	this.playercount.name = "playercount";
	this.playercount.textAlign = "right";
	this.playercount.lineHeight = 26;
	this.playercount.lineWidth = 76;
	this.playercount.parent = this;
	this.playercount.setTransform(743.2,152.6);

	this.login = new cjs.Text("Press Y to login.", "16px 'Arial'", "#FFFFFF");
	this.login.name = "login";
	this.login.lineHeight = 18;
	this.login.lineWidth = 625;
	this.login.parent = this;
	this.login.setTransform(127.6,111.9);

	this.token = new cjs.Text("Connect to xxx and enter token yyy.", "16px 'Arial'", "#FFFFFF");
	this.token.name = "token";
	this.token.lineHeight = 18;
	this.token.lineWidth = 625;
	this.token.parent = this;
	this.token.setTransform(127.6,88.1);

	this.text_2 = new cjs.Text("Instructions", "23px 'Arial'", "#FF873A");
	this.text_2.lineHeight = 26;
	this.text_2.lineWidth = 332;
	this.text_2.parent = this;
	this.text_2.setTransform(127.6,56.6);

	this.activeUsers = new lib.Placeholder();
	this.activeUsers.parent = this;
	this.activeUsers.setTransform(124.7,182,6.2,2);

	this.shape = new cjs.Shape();
	this.shape.graphics.f().s("#592230").ss(3).p("EA7OgnrMh2bgC0MAC0BU/MBxvgC0g");
	this.shape.setTransform(430.7,240.7,0.934,0.8);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#592230").s().p("Eg5TgnLMBymABIMgA/BNMMhtwAADg");
	this.shape_1.setTransform(431.3,237.1,0.934,0.8);

	this.instance = new lib.BG2();
	this.instance.parent = this;
	this.instance.setTransform(0,0,0.445,0.446);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance},{t:this.shape_1},{t:this.shape},{t:this.activeUsers},{t:this.text_2},{t:this.token},{t:this.login},{t:this.playercount},{t:this.text_1},{t:this.start},{t:this.back},{t:this.text}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.PortalViewNoInactive, new cjs.Rectangle(0,0,1004,481.6), null);


(lib.PortalView = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer 2
	this.back = new lib.Button();
	this.back.parent = this;
	this.back.setTransform(279,410.9,1,1,0,0,0,80.6,20.7);

	this.start = new lib.Button();
	this.start.parent = this;
	this.start.setTransform(601.6,410.9,1,1,0,0,0,80.6,20.7);

	this.text = new cjs.Text("Active players", "23px 'Arial'", "#FF873A");
	this.text.lineHeight = 26;
	this.text.lineWidth = 202;
	this.text.parent = this;
	this.text.setTransform(445.6,152.6);

	this.playercount = new cjs.Text("0/8", "23px 'Arial'", "#FF873A");
	this.playercount.name = "playercount";
	this.playercount.textAlign = "right";
	this.playercount.lineHeight = 26;
	this.playercount.lineWidth = 76;
	this.playercount.parent = this;
	this.playercount.setTransform(743.2,152.6);

	this.login = new cjs.Text("Press A to join or Y to login.", "16px 'Arial'", "#FFFFFF");
	this.login.name = "login";
	this.login.lineHeight = 18;
	this.login.lineWidth = 625;
	this.login.parent = this;
	this.login.setTransform(127.6,111.9);

	this.token = new cjs.Text("Connect to xxx and enter token yyy.", "16px 'Arial'", "#FFFFFF");
	this.token.name = "token";
	this.token.lineHeight = 18;
	this.token.lineWidth = 625;
	this.token.parent = this;
	this.token.setTransform(127.6,88.1);

	this.text_1 = new cjs.Text("Instructions", "23px 'Arial'", "#FF873A");
	this.text_1.lineHeight = 26;
	this.text_1.lineWidth = 332;
	this.text_1.parent = this;
	this.text_1.setTransform(127.6,56.6);

	this.text_2 = new cjs.Text("Connected devices", "23px 'Arial'", "#FF873A");
	this.text_2.lineHeight = 26;
	this.text_2.lineWidth = 295;
	this.text_2.parent = this;
	this.text_2.setTransform(127.6,152.8);

	this.activeUsers = new lib.Placeholder();
	this.activeUsers.parent = this;
	this.activeUsers.setTransform(445.2,182.3,3,2);

	this.connectedUsers = new lib.Placeholder();
	this.connectedUsers.parent = this;
	this.connectedUsers.setTransform(125.6,182.3,3,2);

	this.shape = new cjs.Shape();
	this.shape.graphics.f().s("#592230").ss(3).p("EA7OgnrMh2bgC0MAC0BU/MBxvgC0g");
	this.shape.setTransform(430.7,240.7,0.934,0.8);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#592230").s().p("Eg5TgnLMBymABIMgA/BNMMhtwAADg");
	this.shape_1.setTransform(431.3,237.1,0.934,0.8);

	this.instance = new lib.BG2();
	this.instance.parent = this;
	this.instance.setTransform(0,0,0.445,0.446);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance},{t:this.shape_1},{t:this.shape},{t:this.connectedUsers},{t:this.activeUsers},{t:this.text_2},{t:this.text_1},{t:this.token},{t:this.login},{t:this.playercount},{t:this.text},{t:this.start},{t:this.back}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.PortalView, new cjs.Rectangle(0,0,854,481.6), null);


(lib.UserIcon = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Colors (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("AiWhnIEtAAIgPDJIjaAGg");
	mask.setTransform(36.6,33.9);

	// PlayerColor
	this.PlayerColor = new lib.Placeholder();
	this.PlayerColor.parent = this;
	this.PlayerColor.setTransform(0,0,0.75,0.75);

	var maskedShapeInstanceList = [this.PlayerColor];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.PlayerColor).wait(1));

	// Front
	this.instance = new lib.mobile();
	this.instance.parent = this;
	this.instance.setTransform(37,37.6,1,1,0,0,0,20.6,36);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// Main
	this.boundary = new lib.Marker();
	this.boundary.parent = this;
	this.boundary.setTransform(80,80);

	this.timeline.addTween(cjs.Tween.get(this.boundary).wait(1));

}).prototype = getMCSymbolPrototype(lib.UserIcon, new cjs.Rectangle(16.4,1.6,40.9,72), null);


(lib.UserIconTablet = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Colors (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("Aj+E0Iggm0IKBgYIAAHYg");
	mask.setTransform(35.5,32);

	// PlayerColor
	this.PlayerColor = new lib.Placeholder();
	this.PlayerColor.parent = this;
	this.PlayerColor.setTransform(0,8.8,0.783,0.633);

	var maskedShapeInstanceList = [this.PlayerColor];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.PlayerColor).wait(1));

	// Front
	this.shape = new cjs.Shape();
	this.shape.graphics.rf(["#320B1F","#190412"],[0.004,1],0,0,0,0,0,50.6).s().p("AlaERIgQobILVgeIgLJRg");
	this.shape.setTransform(39.4,40.6,1.03,1.03);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	// Main
	this.boundary = new lib.Marker();
	this.boundary.parent = this;
	this.boundary.setTransform(80,80);

	this.timeline.addTween(cjs.Tween.get(this.boundary).wait(1));

}).prototype = getMCSymbolPrototype(lib.UserIconTablet, new cjs.Rectangle(2,10.1,74.8,61.2), null);


(lib.UserIconPhone = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Colors (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("AiWhnIEtAAIgPDJIjaAGg");
	mask.setTransform(36.6,33.9);

	// PlayerColor
	this.PlayerColor = new lib.Placeholder();
	this.PlayerColor.parent = this;
	this.PlayerColor.setTransform(0,0,0.75,0.75);

	var maskedShapeInstanceList = [this.PlayerColor];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.PlayerColor).wait(1));

	// Front
	this.instance = new lib.mobile();
	this.instance.parent = this;
	this.instance.setTransform(37,37.6,1,1,0,0,0,20.6,36);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// Main
	this.boundary = new lib.Marker();
	this.boundary.parent = this;
	this.boundary.setTransform(80,80);

	this.timeline.addTween(cjs.Tween.get(this.boundary).wait(1));

}).prototype = getMCSymbolPrototype(lib.UserIconPhone, new cjs.Rectangle(16.4,1.6,40.9,72), null);


(lib.UserIconKeyboard = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Colors (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("ADaBTIByAIIADBNIh2ACgAiSCoIgQhRIExgEIAABXgAlOCpIAFhSIBqAEIAQBOgAAnggIBYgHIgIBSIhQADgAhYggIBMABIACBMIhhABgAlGApIAEhQIC2AGIgXBMgAC3gsICQAFIAEBMIiaABgAiyiiIBYgHIgIBTIhQADgAk+iiIBcACIACBMIhhABgABQhYIAAhKIBTgHIALBRgAgyhYIAEhRIBOAHIgDBKgADVipIBqABIACBNIhhABg");
	mask.setTransform(39.2,44.2);

	// PlayerColor
	this.PlayerColor = new lib.Placeholder();
	this.PlayerColor.parent = this;
	this.PlayerColor.setTransform(0,8.8,0.783,0.633);

	var maskedShapeInstanceList = [this.PlayerColor];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.PlayerColor).wait(1));

	// Front
	this.shape = new cjs.Shape();
	this.shape.graphics.rf(["#290B19","#0F030A"],[0.004,1],0,0,0,0,0,214.5).s().p("A6fOtIBa8kMAyHgCTMABeAgVg");
	this.shape.setTransform(1.7,20.7,0.22,0.22,0,0,0,-170.9,-106);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	// Main
	this.boundary = new lib.Marker();
	this.boundary.parent = this;
	this.boundary.setTransform(80,80);

	this.timeline.addTween(cjs.Tween.get(this.boundary).wait(1));

}).prototype = getMCSymbolPrototype(lib.UserIconKeyboard, new cjs.Rectangle(2,21.2,74.8,45.6), null);


(lib.UserIconGamepad = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Colors (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("ACrBJQgIgHAAgLIAAAAQAAgKAIgIIAAAAQAHgIALAAIAAAAQALAAAIAIIAAAAQAIAIAAAKIAAAAQAAALgIAHIAAAAQgIAIgLAAIAAAAQgLAAgHgIgAjkBKIASgjIATAjgAjIAXIBFgvIAEBYgAkSgKIA2AfIg0AjgAByARQgGgGAAgHIAAAAQAAgHAGgFIAAAAQAFgFAIAAIAAAAQAIAAAFAFIAAAAQAGAFAAAHIAAAAQAAAHgGAGIAAAAQgFAFgIAAIAAAAQgIAAgFgFgAjpgjIAuAAIgXAtgAD8AGQgDgEAAgEIAAAAQAAgFADgEIAAAAQAEgDAGAAIAAAAQAFAAAEADIAAAAQAEAEAAAFIAAAAQAAAEgEAEIAAAAQgEAEgFAAIAAAAQgGAAgEgEgADAgpQgHgGAAgKIAAAAQAAgKAHgGIAAAAQAHgHAJAAIAAAAQAKAAAHAHIAAAAQAHAGAAAKIAAAAQAAAKgHAGIAAAAQgHAHgKAAIAAAAQgJAAgHgHg");
	mask.setTransform(37.4,34);

	// PlayerColor
	this.PlayerColor = new lib.Placeholder();
	this.PlayerColor.parent = this;
	this.PlayerColor.setTransform(0,0,0.75,0.75);

	var maskedShapeInstanceList = [this.PlayerColor];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.PlayerColor).wait(1));

	// Front
	this.shape = new cjs.Shape();
	this.shape.graphics.rf(["#290B19","#10020A"],[0.004,1],0,0,0,0,0,34.2).s().p("ADbEPIhKi+Ik3AAIgdC+Ih0AAIhYodIDcAZIAnBaIEkADIAThNIDlgWIheIKg");
	this.shape.setTransform(36.8,39.8,0.867,0.867);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	// Main
	this.boundary = new lib.Marker();
	this.boundary.parent = this;
	this.boundary.setTransform(80,80);

	this.timeline.addTween(cjs.Tween.get(this.boundary).wait(1));

}).prototype = getMCSymbolPrototype(lib.UserIconGamepad, new cjs.Rectangle(2.2,16.3,69.4,47.1), null);


(lib.UserIconAuthenticated = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// OriginalIcon
	this.ControllerIcon = new lib.PlaceholderAlternateColor();
	this.ControllerIcon.parent = this;
	this.ControllerIcon.setTransform(36.8,0,0.349,0.349);

	this.timeline.addTween(cjs.Tween.get(this.ControllerIcon).wait(1));

	// PlayerImage
	this.userimage = new lib.PlaceholderAlternateColor();
	this.userimage.parent = this;
	this.userimage.setTransform(5.3,15.3,0.54,0.542);

	this.timeline.addTween(cjs.Tween.get(this.userimage).wait(1));

	// Main
	this.boundary = new lib.Marker();
	this.boundary.parent = this;
	this.boundary.setTransform(80,80);

	this.timeline.addTween(cjs.Tween.get(this.boundary).wait(1));

	// Colors (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("AkcEsQgGAAgFgEQgEgFAAgGIAAo5QAAgGAEgFQAFgEAGAAII5AAQAGAAAFAEQAEAFAAAGIAAI5QAAAGgEAFQgFAEgGAAgAkNEOIIbAAIAAobIobAAg");
	mask.setTransform(32.3,42.4);

	// PlayerColor
	this.PlayerColor = new lib.Placeholder();
	this.PlayerColor.parent = this;
	this.PlayerColor.setTransform(0,0,0.75,0.75);

	var maskedShapeInstanceList = [this.PlayerColor];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.PlayerColor).wait(1));

}).prototype = getMCSymbolPrototype(lib.UserIconAuthenticated, new cjs.Rectangle(2.3,0,69.5,72.4), null);


(lib.Accounts = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Laag 1
	this.scrollbar = new lib.Scrollbar();
	this.scrollbar.parent = this;
	this.scrollbar.setTransform(341.2,15.8,0.567,0.421);

	this.content = new lib.Placeholder_1();
	this.content.parent = this;
	this.content.setTransform(6.2,15.9,3.644,1.599);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.content},{t:this.scrollbar}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.Accounts, new cjs.Rectangle(6.2,14.8,358.7,165.3), null);


(lib.Signin = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer 1
	this.linkAccount = new lib.LongButton();
	this.linkAccount.parent = this;
	this.linkAccount.setTransform(294.3,312.7);

	this.accounts = new lib.Accounts();
	this.accounts.parent = this;
	this.accounts.setTransform(552.8,329,1,1,0,0,0,299.6,196);

	this.title = new cjs.Text("Select your profile", "20px 'Verdana'", "#FF873A");
	this.title.name = "title";
	this.title.lineHeight = 26;
	this.title.lineWidth = 217;
	this.title.parent = this;
	this.title.setTransform(260.5,115.2);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.title},{t:this.accounts},{t:this.linkAccount}]}).wait(1));

	// background
	this.close = new lib.CloseButton();
	this.close.parent = this;
	this.close.setTransform(611.9,130.1,1,1,0,0,0,12.6,19.4);

	this.shape = new cjs.Shape();
	this.shape.graphics.f("#290B19").s().p("A6MNCIAA6DMA0ZAAAIAAaDg");
	this.shape.setTransform(261.4,151.6,1,1,0,0,0,-161.3,-76.9);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f().s("#51232D").ss(3).p("Eg+HgfhMgBtA/DMB/pgA8MgAJg9pg");
	this.shape_1.setTransform(242.1,96.4,0.483,0.68,0,0,0,-397.7,-201.9);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#51232D").s().p("Eg+TAdhMABXg6vMB64gASMAAYA7Bg");
	this.shape_2.setTransform(244.6,102.5,0.474,0.684,0,0,0,-400.3,-188.6);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("rgba(0,0,0,0.749)").s().p("EhCwAlnMAAAhLNMCFhAAAMAAABLNg");
	this.shape_3.setTransform(427,240.7);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_3},{t:this.shape_2},{t:this.shape_1},{t:this.shape},{t:this.close}]}).wait(1));

}).prototype = getMCSymbolPrototype(lib.Signin, new cjs.Rectangle(-0.2,0,913.6,481.5), null);


// stage content:
(lib.remote = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Auth
	this.instance = new lib.Signin();
	this.instance.parent = this;
	this.instance.setTransform(359.4,240,1,1,0,0,0,359.4,240);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// Portal
	this.instance_1 = new lib.PortalViewNoInactive();
	this.instance_1.parent = this;
	this.instance_1.setTransform(430.2,239.7,1,1,0,0,0,430.2,239.7);

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(426.8,240,1004.3,481.6);
// library properties:
lib.properties = {
	id: '5F84D68D1234D9449986F9EAC842AF45',
	width: 854,
	height: 480,
	fps: 24,
	color: "#FFFFFF",
	opacity: 1.00,
	webfonts: {},
	manifest: [
		{src:"images/HD_background_02.jpg", id:"HD_background_02"}
	],
	preloads: []
};



// bootstrap callback support:

(lib.Stage = function(canvas) {
	createjs.Stage.call(this, canvas);
}).prototype = p = new createjs.Stage();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

an.bootcompsLoaded = an.bootcompsLoaded || [];
if(!an.bootstrapListeners) {
	an.bootstrapListeners=[];
}

an.bootstrapCallback=function(fnCallback) {
	an.bootstrapListeners.push(fnCallback);
	if(an.bootcompsLoaded.length > 0) {
		for(var i=0; i<an.bootcompsLoaded.length; ++i) {
			fnCallback(an.bootcompsLoaded[i]);
		}
	}
};

an.compositions = an.compositions || {};
an.compositions['5F84D68D1234D9449986F9EAC842AF45'] = {
	getStage: function() { return exportRoot.getStage(); },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};

an.compositionLoaded = function(id) {
	an.bootcompsLoaded.push(id);
	for(var j=0; j<an.bootstrapListeners.length; j++) {
		an.bootstrapListeners[j](id);
	}
}

an.getComposition = function(id) {
	return an.compositions[id];
}



})(createjs = createjs||{}, AdobeAn = AdobeAn||{});
var createjs, AdobeAn;
/*** EXPORTS FROM exports-loader ***/
module.exports = AdobeAn;

import './styles/app.scss';

import {BingoService} from "./services/bingo.service";
import {ApiService} from "./services/api.service";
import {BingoMapper} from "./mappers/bingo.mapper";
import {CardGenerator} from "./generator/card.generator";
import {CardPrinter} from "./printer/card.printer";
import {RootView} from "./views/easelbone/root.view";

const easelbone = require('easelbone');
const createjs = require('easeljs');
const tweenjs = require('tweenjs');

// Add our own movieclip functionality
easelbone.Helpers.MovieClipHelper.attach(createjs);

import {Theme} from "./utils/theme.util";
import {BingoCardMapper} from "./mappers/bingoCard.mapper";
import {Card} from "./models/card.model";
import {CardView} from "./views/cards/card.view";
import {BingoSessionService} from "./services/bingoSession.service";
import {BingoSessionMapper} from "./mappers/bingoSession.mapper";

const assets = require('./assets/assets').Assets;

export class Application {

    private canvas: any;

    public rootView: any;

    public publisherId: number;

    public services = {
        bingoService: new BingoService(this),
        bingoSessionService: new BingoSessionService(this),
    }

    public mappers = {
        bingoMapper: new BingoMapper(this),
        bingoCardMapper: new BingoCardMapper(this),
        bingoSessionMapper: new BingoSessionMapper(this)
    };

    public async initialize() {

        this.canvas = document.createElement('canvas');
        document.body.appendChild(this.canvas);

        this.setCanvasSize();
        window.addEventListener('resize', () => {
            this.setCanvasSize();
        });

        createjs.Ticker.framerate = 24;
        createjs.Ticker.timingMode = createjs.Ticker.TIMEOUT;

        this.rootView = new RootView({
            canvas: this.canvas,
            webgl: false
        });
        this.rootView.setMaxCanvasSize(1920, 1080);

        // CatLab Loading screen
        const loading = new easelbone.Views.Loading({
            gameVersion: '1.0'
        });

        this.rootView.setView(loading);

        const loader = new easelbone.Loader();

        // Load webremote
        loader.task(
            () => {
                return new Promise(async (resolve, reject) => {


                    //const viewFactory = new catlabremote.ViewFactories.CreatejsViewFactory(assets.portalassets);
                    /*Webcontrol.setViewFactory(viewFactory).then(() => {
                        Webcontrol.initialize({
                            serverlist: 'https://remote.catlab.eu/servers.json'
                        })
                            .then(
                                () => {

                                    // Load the views
                                    const viewUrl = Url.getAbsoluteUrl(viewFilename);
                                    Webcontrol.loadViews(viewUrl, true);

                                    // contact parent screen
                                    if (window.parent) {
                                        window.parent.postMessage(Webcontrol.getConnectToken());
                                    }

                                    resolve(true);

                                }
                            );
                    });*/

                    await this.loadFonts();
                    resolve(true);

                });
            }
        );

        loader.loadComposition(assets.bingo, 'assets/bingo/');

        await loader.load({
            progress: (progress: number) => {
                loading.setProgress(Math.ceil(progress * 100));
            }
        });

        Theme.setLibrary(assets.bingo.getLibrary());
    }

    /**
     * @param accessToken
     */
    public setAccessToken(accessToken: string)
    {
        ApiService.setAccessToken(accessToken);
    }

    /**
     * @param publisherId
     */
    public setPublisherId(publisherId: number)
    {
        this.publisherId = publisherId;
    }

    /**
     *
     */
    public async printCards(
        bingoId: number,
        options: {
            amount?: number,
            cardsPerPage?: number,
            paperSize?: string,
            shufflePerPage?: boolean
        }
    ) {
        const amount = options.amount ?? 12;
        const cardsPerPage = options.cardsPerPage || 4;
        const paperSize = options.paperSize || 'a4';
        const shufflePerPage = options.shufflePerPage || true;

        await this.initialize();

        const bingo = await this.services.bingoService.getBingo(bingoId);

        const generator = new CardGenerator(5, 5, bingo);

        const cards: Card[] = [];

        for (let i = 0; i < amount; i ++) {
            if (!shufflePerPage || i % cardsPerPage === 0) {
                generator.resetRandomizedNumbers();
            }

            cards.push(generator.generate());
        }

        // Store them
        await this.services.bingoService.storeCards(this.publisherId, bingo, cards);

        // Print them
        const printer = new CardPrinter(
            options.paperSize,
            options.cardsPerPage
        );
        await printer.initialize();

        await printer.printCards(cards);

        await printer.write();

        return new Promise(
            (resolve, reject) => {
                setTimeout(resolve, 10000);
            }
        );
    }

    public async showCard(bingoId: number)
    {
        await this.initialize();

        const bingo = await this.services.bingoService.getBingo(bingoId);

        const generator = new CardGenerator(5, 5, bingo);
        const card = generator.generate();

        const view = new CardView(card);

        setTimeout(
            () => {
                this.rootView.setView(view);
            }, 1000);
    }

    public async publicSessionCard(sessionId: string, sessionKey: string) {

        await this.initialize();

        const session = await this.services.bingoSessionService.getPublicSession(sessionId, sessionKey);

        // Do we have a card?
        let card = null;
        try {
            let existingCardCredentials: any = window.localStorage.getItem('bingo_' + sessionId + '_card');
            if (existingCardCredentials) {
                existingCardCredentials = JSON.parse(existingCardCredentials);

                card = await this.services.bingoSessionService.getCardPublic(session, existingCardCredentials.id, existingCardCredentials.secret);

                card.restoreStateFromLocalStorage(existingCardCredentials);
                card.localStorageKey = 'bingo_' + sessionId + '_card';
            }
        } catch (e) {
            console.error(e);
        }

        if (!card) {
            const generator = new CardGenerator(5, 5, session.bingo);
            card = generator.generate();

            await this.services.bingoSessionService.storeCardPublic(session, card);

            // And set in localstorage
            card.localStorageKey = 'bingo_' + sessionId + '_card';
            card.storeStateInLocalStorage();
        }

        const view = new CardView(card);
        this.rootView.setView(view);

    }

    private setCanvasSize() {

        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;

        if (this.rootView) {
            this.rootView.render();
        }
    }

    protected async loadFonts() {

        const fonts = [
            {
                name: 'Ewert',
                offset: [0, 0.1]
            },
            {
                name: 'Graduate',
                offset: [0, 0.1]
            }
        ];

        const p = document.createElement('p');
        p.style.position = 'absolute';
        p.style.top = '-50px';
        document.body.appendChild(p);

        fonts.forEach(
            (font) => {
                p.innerHTML += '<span style="font-family: \'' + font.name + '\';">' + font.name + '</span>';

                easelbone.EaselJS.BigText.setFontOffset(
                    font.name,
                    font.offset[0],
                    font.offset[1]
                );
            }
        );

        /*
        return new Promise<void>(
            (resolve, reject) => {
                setTimeout(() => {
                    resolve();
                }, 1000);
            }
        );*/


        return document.fonts.ready;
    }
}

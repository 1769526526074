import {BaseMapper} from "./base.mapper";

export class BingoSessionMapper extends BaseMapper {
	public toModel(data: any) {
		const out: any = {};

		out.id = data.id;
		out.token = data.token;
		out.bingo = this.application.mappers.bingoMapper.toModel(data.bingo);

		return out;
	}
}

import axios, {AxiosInstance} from 'axios';
import {Application} from "../application";
import {Config} from "../config";

export class ApiService
{
    /**
     * @private
     */
    private static httpClient: AxiosInstance;

    constructor(
        protected application: Application
    ) {}

    /**
     *
     */
    public static getHttpClient()
    {
        if (!this.httpClient) {
            this.httpClient = axios.create({
                baseURL: Config.api
            });
        }

        return this.httpClient;
    }

    /**
     * @param accessToken
     */
    public static setAccessToken(accessToken: string)
    {
        this.getHttpClient().defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    }
}

import {BaseMapper} from "./base.mapper";
import {Card} from "../models/card.model";
import {Bingo} from "../models/bingo.model";

export class BingoCardMapper extends BaseMapper {
    public toData(card: Card) {
        const out: any = {};
        out.active = true;
        out.width = card.width;
        out.height = card.height;

        out.items = [];
        for (let i = 0; i < card.width; i ++) {
            for (let j = 0; j < card.height; j ++) {
                if (!card.isFree(i, j)) {
                    let object = card.getCell(i, j);
                    out.items.push({
                        x: i,
                        y: j,
                        option: object.id
                    });
                }
            }
        }

        return out;
    }

    /**
     * @param data
     */
    public toModel(bingo: Bingo, data: any): Card
    {
        const card = new Card(data.width, data.height);
        card.id = data.id;
        card.active = data.active;
        card.token = data.token;
        card.secret = data.secret;

        data.items.items.forEach(
            (optionData: any) => {
                const option = bingo.getOption(optionData.option_id);
                card.setCell(optionData.x, optionData.y, option);
            }
        );

        return card;
    }
}

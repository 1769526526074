import {Bingo} from "../models/bingo.model";
import {Card} from "../models/card.model";
import {Option} from "../models/option.model";

/**
 *
 */
export class CardGenerator
{
    private sets: any = [];

    private randomizedSets: any = [];

    private centerRow: number = null;
    private centerColumn: number = null;

    /**
     * @param width
     * @param height
     * @param bingo
     * @param freeCenter
     */
    constructor(
        private width: number,
        private height: number,
        private bingo: Bingo,
        private freeCenter: boolean = true
    ) {
        let optionsPerSet = bingo.options.length / width;

        let options = [... bingo.options];
        let overflow = 0;

        for (let i = 0; i < width; i ++) {
            // last item? add everything
            if (i === width - 1) {
                this.sets.push(options);
            } else {
                overflow += optionsPerSet - Math.floor(optionsPerSet);
                if (overflow > 1) {
                    overflow = 0;
                    this.sets.push(options.splice(0, optionsPerSet + 1));
                } else {
                    this.sets.push(options.splice(0, optionsPerSet));
                }
            }
        }

        this.centerRow = Math.ceil(this.height / 2) - 1;
        this.centerColumn = Math.ceil(this.width / 2) - 1;
    }

    /**
     *
     */
    public generate()
    {
        if (this.bingo.options.length < (this.width * this.height)) {
            throw new Error('Cannot generate bingo card when there are less options than fields.');
        }

        const card = new Card(this.width, this.height);

        this.prepareRandomizedSets();

        for (let c = 0; c < this.width; c ++) {
            let randomNumbers = this.pickRandomNumbers(c, this.height);

            for (let r = 0; r < this.height; r ++) {
                if (
                    this.freeCenter &&
                    r === this.centerRow &&
                    c === this.centerColumn
                ) {
                    card.markFree(c, r);
                } else {
                    card.setCell(c, r, randomNumbers[r]);
                }
            }
        }

        return card;
    }

    /**
     * Either after each card or after each page, reset the randomized numbers
     */
    public resetRandomizedNumbers()
    {
        this.randomizedSets = [];
    }

    /**
     * @private
     */
    private prepareRandomizedSets()
    {
        if (this.hasEnoughRandomizedItems()) {
            return;
        }

        this.randomizedSets = [];
        for (let i = 0; i < this.width; i ++) {
            this.randomizedSets[i] = [ ... this.sets[i] ];
            this.shuffle(this.randomizedSets[i]);
        }
    }

    /**
     * @private
     */
    private hasEnoughRandomizedItems() {
        // Do we have enough items?
        //return this.countRandomizedItems() >= this.countRequiredItems();
        if (this.randomizedSets.length === 0) {
            return false;
        }

        for (let i = 0; i < this.width; i ++) {
            if (this.randomizedSets[i].length < this.getColumnHeight(i)) {
                console.log('We don\'t have enough random items in the sets. Replenishing.');
                return false;
            }
        }

        return true;
    }

    /**
     * Count the amount of items in our randomized sets
     * @private
     */
    private countRandomizedItems()
    {
        let items = 0;
        for (const set of this.randomizedSets) {
            items += set.length;
        }
        return items;
    }

    private countRequiredItems()
    {
        let requiredItems = this.width * this.height;
        if (this.freeCenter) {
            requiredItems --;
        }
        return requiredItems;
    }

    /**
     * @param column
     * @param amount
     * @private
     */
    private pickRandomNumbers(column: number, amount: number)
    {
        //console.log(JSON.stringify(this.randomizedSets));
        if (
            typeof(this.randomizedSets[column]) === 'undefined' ||
            this.randomizedSets[column].length < amount)
        {
            throw new Error('Not enough random sets prepared. Please call prepareRandomizedSets before generating cards.');
        }

        const out: Option[] = [];
        for (let i = 0; i < amount; i ++) {
            out.push(this.randomizedSets[column].shift());
        }

        return out;
    }

    /**
     * @param array
     * @private
     */
    private shuffle(array: Option[]): Option[]
    {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    };

    private getColumnHeight(column: number) {
        if (this.freeCenter && column === this.centerColumn) {
            return this.height - 1;
        } else {
            return this.height;
        }
    }
}

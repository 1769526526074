// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/Graduate-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/Ewert-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Graduate\";font-style:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-family:\"Ewert\";font-style:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}p.ewert{font-family:Ewert,sans-serif}p.graduate{font-family:Graduate,sans-serif}", "",{"version":3,"sources":["webpack://./src/styles/app.scss"],"names":[],"mappings":"AAAA,WACE,sBAAA,CACA,iBAAA,CACA,8DAAA,CAGF,WACE,mBAAA,CACA,iBAAA,CACA,8DAAA,CAIF,QACE,4BAAA,CAGF,WACE,+BAAA","sourcesContent":["@font-face {\n  font-family: 'Graduate';\n  font-style: normal;\n  src: url('fonts/Graduate-Regular.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Ewert';\n  font-style: normal;\n  src: url('fonts/Ewert-Regular.ttf') format('truetype');\n}\n\n\np.ewert {\n  font-family: Ewert, sans-serif;\n}\n\np.graduate {\n  font-family: Graduate, sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

const easelbone = require('easelbone');

export class ThemeUtil {

    private library: any;

    public setLibrary(library: any)
    {
        this.library = library;
        easelbone.setProperties(this.getProperties());
    }

    public getProperties(): any {
        return this.library.properties;
    }

    public get(name: string)
    {
        return new (this.library[name]);
    }

}

const Theme = new ThemeUtil();
export { Theme };
(function (cjs, an) {

var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.ssMetadata = [
		{name:"bingo_atlas_", frames: [[0,0,512,512]]}
];


// symbols:



(lib.FreeSpace = function() {
	this.initialize(ss["bingo_atlas_"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();
// helper functions:

function mc_symbol_clone() {
	var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop));
	clone.gotoAndStop(this.currentFrame);
	clone.paused = this.paused;
	clone.framerate = this.framerate;
	return clone;
}

function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
	var prototype = cjs.extend(symbol, cjs.MovieClip);
	prototype.clone = mc_symbol_clone;
	prototype.nominalBounds = nominalBounds;
	prototype.frameBounds = frameBounds;
	return prototype;
	}


(lib.PlaceholderBackground = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Laag_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#FF00FF").s().p("AnzH0IAAvnIPnAAIAAPng");
	this.shape.setTransform(50,50);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.PlaceholderBackground, new cjs.Rectangle(0,0,100,100), null);


(lib.CrossSymbol = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#FF0000").s().p("Ar7LOIgFgBIgHgDIgFgDIgFgEIgDgEIgDgEIgDgFIgBgFIgBgFIAAgFIAAgFIABgFIABgFIADgFIADgEQAeggAogVQAmgUAlgWQAcgQAZgWQAdgZAbgdQAWgYAcgSIAGgEQA0g1A6gtQAxgmAvgoQAugpAzgkQAwgiAqgpQAkgkAiglIACgEIADgIIAGgLIAFgKIAIgJIAIgHIAFgEIAQgTQAJgKAGgLIAQgVIAPgUQAFgGAIgFIAFgEIAIgJIAJgKIAIgJIAIgIIAEgEQANgRAPgQQANgOAQgNQAMgJALgNQAKgOAPgJIAMgJIALgJIAKgKIAJgKIAJgJIAJgJIACgEIADgFIADgEIAMgOIAQgNIASgMIARgLIAGgEIAWgVIAUgVIATgSQAKgJALgHIALgHIAEgEIAFgEIAEgEIAEgDIAEgDIAFgDIADgCIAFgDIAAAAIAEgFIAFgGIAIgKIAJgLIAIgJIAKgIIAJgHIAEgEIAFgDIAEgDIAFgDIAEgEIAFgFIAEgEIAGgEIAFgFIAGgEIAEgFIAFgFIAEgEIAFgEIAEgDIAEgEIAEgDIAFgGIAEgEIAGgDIAFgEIAFgEIAGgEIAFgEIAGgEIAEgEIAFgDIAEgGIAEgDIADgEIADgDIADgCIADgEIAEgEIAEgDIADgEIAFgFIAGgDIAFgDIAGgDIAEgBIAGgBIAFgBIAFABIAFABIAEABIAGACIADADIAFADIADAEIADAFIADAEIABAFIABAEIABAFIAAAFIgBAFIgBAFIgCAEIgCAEIgDAEIgDAEIgEADIgEADIgBAAIgEAEIgDADIgEAEIgFAFIgEAEIgEADIgCADIgDACIgEAEIgDAEIgEACIgEAFIgGAEIgEADIgFADIgEAEIgFADIgFAEIgFAEIgFAEIgEAFIgFAEIgFAEIgFAEIgCACIgEAFIgEAFIgFAEIgGAFIgDABIgFAFIgHAFIgFAFIgGAFIgFAEIgGAEIgFAEIgEAEIgGADIgFAFIgFAGIgFAFIgEAFIgDAFIgEAFIgEAEIgDAEIgEAEIgDAEIgEAEIgEADIgGADIgEADIgFACIgEAFIgFAEIgFAEIgGAEIgGAEIgFAEIgGADIgYAXIgXAYQgNANgNAKQgMAJgMAIIgSALIgFAFIgGAFQgGANgKAKIgVAWIgSASIgQANIgQALQgKAIgIAKQgHALgLAIIgWATIgNAOIgIAIIgEAFIgDAFIgEAFIgEAFIgHAGIgIAIIgJAKIgJAKIgIAIIgJAHIgDAEIgEAEIgPAVQgIAMgJALIgSAWQgFAHgIAHIgHAGQg5BahVBCQhYBEhUBHIg9AzIiaCFIhGA9Qg7A2hHAlQgeAQgcASIgEAEIgGAFIgEAEIgGADIgGADIgFABIgHABIgGgBgAEvlGIgBABIAAAAIABgCIAAAAIAAABgAH3n1IgBACIABgBIABgBg");
	this.shape.setTransform(67.75,70.125);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#FF0000").s().p("ALRLTIgHgCIgHgCIgEgEIgGgDIgDgEIgFgEIgFgFIgEgDIgCgEIgDgEIgEgEIgDgEIgCgEIgDgEIgDgGIgCgFIgEgGIgDgFIgEgGIgEgFIgDgFIgEgGIgDgFIgKgKIgJgKIgJgKIgJgKIgKgRIgEgDIgEgDIgFgEIgFgDIgIgJIgLgJIgLgJIgHgIIgHgJIgMgJQgMgIgLgJIgTgRIgRgOQgGgHgGgHIgFgGIABAAIgFgFQgLgHgJgIQgKgJgLgIQgOgLgNgMQgJgJgHgLIgKgRIgJgMIgFgHIgFgEIgHgJIgJgIIgJgHIgDgDIgDgFIgDgEIgEgFIgFgEQhmhahvhQQh2hUhxhZQhnhRhdheQgugtgwgoQhdhLhLhdQhAhQhFhKIgDgFIgBgFIgCgEIgBgGIgBgFIABgFIABgFIACgFIACgFIACgEIAEgEIAEgEIAFgDIAEgCIAGgBIAEgBIAGgBIAEABIAFABIAGABIAEADIAFADQBhBrBgBtQA7BCBDA5QBRBEBLBKQBfBdBsBOIDFCPQAlAbAkAeQAfAaAdAbIAJAGIAKAHIAJAHIAIAIIAGAJIAEAEQAJAHAIAHIAMAPIASAYQAIAIAGAJIAGAMIAKAJIAKAIIALAIIAKAJIALAJIAFAEIAFAEIAFADIAEAFIAGAEIADAEIADAEIADAFIACACIAPAOIAPANQALAKAMAIIAPALQAIAHAGAIIAEAFIAEADIAFAEIAEADIADAEIAGAEIAFAFIAKAIIAJAIIAIAJIAHAKIAHALIAJAJIAIAJIAKAKIAIAJIAHAJIACAFIADAGIACABIAAABIABABIABABIAEAFIAEAFIADAFIACAFIAGAMIAEAGIAEAFIAEAFIgDgEIADADIAFAEIAAAAIAEADIAEADIADAEIADAEIACAEIACAEIABAFIABAFIAAAFIgBAEIgBAFIgBAFIgDAEIgDAFIgDAEIgFADIgDADIgFACIgFABIgFABIgFABIgHgBg");
	this.shape_1.setTransform(71.75,70.425);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.CrossSymbol, new cjs.Rectangle(-12,-1.9,160.5,144.70000000000002), null);


(lib.Placeholder = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.instance = new lib.PlaceholderBackground();

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.Placeholder, new cjs.Rectangle(0,0,100,100), null);


(lib.Cross = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.instance = new lib.CrossSymbol();
	this.instance.setTransform(81.3,72.25,1,1,0,0,0,69.3,70.3);
	this.instance.cache(-14,-4,165,149);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.Cross, new cjs.Rectangle(0,0,160.5,144.8), null);


(lib.CardPrint = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.serial = new lib.Placeholder();
	this.serial.setTransform(525,1204.65,8,0.3,0,0,0,50,50.1);

	this.title = new lib.Placeholder();
	this.title.setTransform(525,217.4,8,2.25,0,0,0,50,50.1);

	this.card = new lib.Placeholder();
	this.card.setTransform(525,760.9,8.1499,8.1499,0,0,0,50.1,50);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.card},{t:this.title},{t:this.serial}]}).wait(1));

	// Layer_3
	this.shape = new cjs.Shape();
	this.shape.graphics.f().s("#006C66").ss(5,1,1).p("EBALAMgMiA5AAAEBAvglfMiA5AAAEBALgMfMiA5AAAEBALAlgMiA5AAA");
	this.shape.setTransform(524.4,760.9);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	// Layer_2
	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f().s("#006C66").ss(5,1,1).p("EglfhAuMAAACA5EgMfhAKMAAACA5EAMghAKMAAACA5EAlghAKMAAACA5");
	this.shape_1.setTransform(523,760.7);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f().s("#006C66").ss(15,1,1).p("EhAxhAxIbOAAMBmVAAAMAAACBjMiBjAAAg");
	this.shape_2.setTransform(523.45,761);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#ECF0F1").s().p("EhAxBAyMAAAiBjIbNAAMBmWAAAMAAACBjgEAlcBAsMAAAiA5gEAMcBAsMAAAiA5gEgMkBAsMAAAiA5gEglkBAIMAAAiA5g");
	this.shape_3.setTransform(523.45,761);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_3},{t:this.shape_2},{t:this.shape_1}]}).wait(1));

	// Layer_4
	this.shape_4 = new cjs.Shape();
	this.shape_4.graphics.f("#FFFFFF").s().p("EhSBB0BMAAAjoBMCkDAAAMAAADoBg");
	this.shape_4.setTransform(525,742.5);

	this.timeline.addTween(cjs.Tween.get(this.shape_4).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.CardPrint, new cjs.Rectangle(0,0,1050,1485), null);


(lib.Card = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.card = new lib.Placeholder();
	this.card.setTransform(496,496,10,10,0,0,0,49.6,49.6);

	this.timeline.addTween(cjs.Tween.get(this.card).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.Card, new cjs.Rectangle(0,0,1000,1000), null);


(lib.CardView = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	// Layer_1
	this.instance = new lib.Card();
	this.instance.setTransform(952.75,494.05,1,1,0,0,0,451.1,451.1);

	this.shape = new cjs.Shape();
	this.shape.graphics.f().s("#333333").ss(1,1,1,3,true).p("EiV/hUXMEr/AAAMAAACovMkr/AAAg");
	this.shape.setTransform(960,540);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("rgba(51,51,51,0)").s().p("EiV/BUYMAAAiovMEr/AAAMAAACovg");
	this.shape_1.setTransform(960,540);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape},{t:this.instance}]}).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.CardView, new cjs.Rectangle(-1,-1,1922,1082), null);


// stage content:
(lib.bingo = function(mode,startPosition,loop) {
	this.initialize(mode,startPosition,loop,{});

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,0,0);
// library properties:
lib.properties = {
	id: '1249824E1B14074AAF16C23B3DF271D9',
	width: 1920,
	height: 1080,
	fps: 24,
	color: "#FFFFFF",
	opacity: 1.00,
	manifest: [
		{src:"images/bingo_atlas_.png", id:"bingo_atlas_"}
	],
	preloads: []
};



// bootstrap callback support:

(lib.Stage = function(canvas) {
	createjs.Stage.call(this, canvas);
}).prototype = p = new createjs.StageGL();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

an.bootcompsLoaded = an.bootcompsLoaded || [];
if(!an.bootstrapListeners) {
	an.bootstrapListeners=[];
}

an.bootstrapCallback=function(fnCallback) {
	an.bootstrapListeners.push(fnCallback);
	if(an.bootcompsLoaded.length > 0) {
		for(var i=0; i<an.bootcompsLoaded.length; ++i) {
			fnCallback(an.bootcompsLoaded[i]);
		}
	}
};

an.compositions = an.compositions || {};
an.compositions['1249824E1B14074AAF16C23B3DF271D9'] = {
	getStage: function() { return exportRoot.stage; },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};

an.compositionLoaded = function(id) {
	an.bootcompsLoaded.push(id);
	for(var j=0; j<an.bootstrapListeners.length; j++) {
		an.bootstrapListeners[j](id);
	}
}

an.getComposition = function(id) {
	return an.compositions[id];
}


an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {		
	var lastW, lastH, lastS=1;		
	window.addEventListener('resize', resizeCanvas);		
	resizeCanvas();		
	function resizeCanvas() {			
		var w = lib.properties.width, h = lib.properties.height;			
		var iw = window.innerWidth, ih=window.innerHeight;			
		var pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;			
		if(isResp) {                
			if((respDim=='width'&&lastW==iw) || (respDim=='height'&&lastH==ih)) {                    
				sRatio = lastS;                
			}				
			else if(!isScale) {					
				if(iw<w || ih<h)						
					sRatio = Math.min(xRatio, yRatio);				
			}				
			else if(scaleType==1) {					
				sRatio = Math.min(xRatio, yRatio);				
			}				
			else if(scaleType==2) {					
				sRatio = Math.max(xRatio, yRatio);				
			}			
		}			
		domContainers[0].width = w * pRatio * sRatio;			
		domContainers[0].height = h * pRatio * sRatio;			
		domContainers.forEach(function(container) {				
			container.style.width = w * sRatio + 'px';				
			container.style.height = h * sRatio + 'px';			
		});			
		stage.scaleX = pRatio*sRatio;			
		stage.scaleY = pRatio*sRatio;			
		lastW = iw; lastH = ih; lastS = sRatio;            
		stage.tickOnUpdate = false;            
		stage.update();            
		stage.tickOnUpdate = true;		
	}
}


})(createjs = createjs||{}, AdobeAn = AdobeAn||{});
var createjs, AdobeAn;
/*** EXPORTS FROM exports-loader ***/
module.exports = AdobeAn;

import {Option} from "./option.model";

export class Card
{
    public options: Option[] = [];
    private checkedFields: {
        date: Date,
        column: number,
        row: number
    }[] = [];

    public token: string;
    public secret: string;

    public id: number;
    public active: boolean;

    public localStorageKey: string;

    /**
     * @param width
     * @param height
     */
    constructor(
        public width: number,
        public height: number
    ) {
    }

    /**
     * @param column
     * @param row
     * @param option
     */
    public setCell(
        column: number,
        row: number,
        option: Option|null
    ) {
        this.options[this.getIndex(column, row)] = option;
        return this;
    }

    /**
     * @param column
     * @param row
     */
    public getCell(
        column: number,
        row: number
    ): Option {
        if (typeof(this.options[this.getIndex(column, row)]) === 'undefined') {
            return null;
        }
        return this.options[this.getIndex(column, row)];
    }

    public isFree(
        column: number,
        row: number
    ) {
        return this.getCell(column, row) === null;
    }

    /**
     * @param column
     * @param row
     * @return number
     * @private
     */
    private getIndex(column: number, row: number)
    {
        return row * this.height + column;
    }

    markFree(column: number, row: number) {
        this.setCell(column, row, null);
    }

    public markChecked(column: number, row: number) {
        // check if already checked
        if (this.checkedFields.find(field => field.column === column && field.row === row)) {
            return false;
        }

        this.checkedFields.push({
            date: new Date(),
            column: column,
            row: row
        });

        this.storeStateInLocalStorage();
        return true;
    }

    public markUnchecked(column: number, row: number) {
        let index = this.checkedFields.findIndex(field => field.column === column && field.row === row);
        if (index !== -1) {
            this.checkedFields.splice(index, 1);
            this.storeStateInLocalStorage();
        }
    };

    public isChecked(column: number, row: number) {
        return !!this.checkedFields.find(field => field.column === column && field.row === row);
    }

    public restoreStateFromLocalStorage(state: any) {
        if (typeof(state.checkedFields) !== 'undefined') {
            this.checkedFields = state.checkedFields;
        }
    };

    public storeStateInLocalStorage() {
        window.localStorage.setItem(this.localStorageKey, JSON.stringify({
            id: this.id,
            secret: this.secret,
            checkedFields: this.checkedFields
        }));
    }
}

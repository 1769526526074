import {Bingo} from "../models/bingo.model";
import {BaseMapper} from "./base.mapper";
import {Option} from "../models/option.model";

export class BingoMapper extends BaseMapper {

    /**
     * @param data
     */
    public toModel(data: any): Bingo
    {
        const bingo = new Bingo();
        bingo.id = data.id;

        data.options.items.forEach(
            (optionData: any) => {
                bingo.options.push(this.toOptionModel(optionData));
            }
        );

        return bingo;
    }

    /**
     * @param data
     */
    public toOptionModel(data: any): Option
    {
        const option = new Option();
        option.id = data.id;
        option.optionId = data.option;
        option.text = data.text;

        return option;
    }
}

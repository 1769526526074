import {ApiService} from "./api.service";
import {Card} from "../models/card.model";
import {Bingo} from "../models/bingo.model";
import {BingoSession} from "../models/bingoSession.model";

export class BingoSessionService extends ApiService {

	async getPublicSession(id: string, token: string) {

		const httpClient = ApiService.getHttpClient();

		const data = await httpClient.get('bingo-sessions-public/' + id + '/' + token);

		return this.application.mappers.bingoSessionMapper.toModel(data.data.bingoSession);

	}

	public async storeCardPublic(bingoSession: BingoSession, card: Card)
	{
		const cardData = this.application.mappers.bingoCardMapper.toData(card);

		const httpClient = ApiService.getHttpClient();
		const data: any = await httpClient.post(
			'bingo-sessions-public/' + bingoSession.id + '/' + bingoSession.token +'/cards',
			{
				bingoCard: cardData
			}
		);

		card.id = data.data.bingoCard.id;
		card.token = data.data.bingoCard.token;
		card.secret = data.data.bingoCard.secret;
	}

	public async getCardPublic(bingoSession: BingoSession, cardId: string, secret: string): Promise<Card>
	{
		const httpClient = ApiService.getHttpClient();

		const request = await httpClient.get('bingo-sessions-public/' + bingoSession.id + '/' + bingoSession.token + '/cards/' + cardId, {
			headers: {
				'Authorization': 'Card ' + secret
			}
		});

		return this.application.mappers.bingoCardMapper.toModel(bingoSession.bingo, request.data.bingoCard);
	}
}

import {Option} from "./option.model";

export class Bingo {
    public id: number;

    public width: number;
    public height: number;

    public options: Option[] = [];

    public getOption(optionId: number)
    {
        return this.options.find(option => option.id === optionId);
    }
}

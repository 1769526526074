import {ApiService} from "./api.service";
import {Card} from "../models/card.model";
import {Bingo} from "../models/bingo.model";
import {BingoSession} from "../models/bingoSession.model";

export class BingoService extends ApiService {

    /**
     * @param id
     */
    public async getBingo(id: number)
    {
        const httpClient = ApiService.getHttpClient();

        const data = await httpClient.get('bingos/' + id);

        return this.application.mappers.bingoMapper.toModel(data.data.bingo);
    }

    /**
     * @param publisherId
     * @param bingo
     * @param cards
     */
    public async storeCards(publisherId: number, bingo: Bingo, cards: Card[])
    {
        const body: any = {
            items: []
        };

        cards.forEach(
            (card: Card) => {
                body.items.push(this.application.mappers.bingoCardMapper.toData(card));
            }
        );
        
        const httpClient = ApiService.getHttpClient();
        const data = await httpClient.post(
            'bingos/' + bingo.id + '/publishers/' + publisherId + '/cards',
            body
        );

        let index = 0;
        data.data.items.forEach(
            (data: any) => {
                cards[index].id = data.id;
                cards[index].token = data.token;
                cards[index].secret = data.secret;

                index ++;
            }
        )
    }
}

const easeljs = require('easeljs');
const assets = require('./portal/remote');
const kittenAssets = require('./bingo/bingo');

const PORTAL_ASSETS = '5F84D68D1234D9449986F9EAC842AF45';
const BINGO_COMP = '1249824E1B14074AAF16C23B3DF271D9';

const portalcomp = assets.compositions[PORTAL_ASSETS];

const Assets = {

    portalassets: portalcomp.getLibrary(),

    bingo: kittenAssets.compositions[BINGO_COMP]

};

export { Assets };

import {Card} from "../models/card.model";
import { jsPDF } from 'jspdf';
import {CardView} from "../views/cards/card.view";
import {RootView} from '../views/easelbone/root.view';
const createjs = require('easeljs');

const LANDSCAPE = 'horizontal';
const PORTRAIT = 'vertical';

export class CardPrinter {

    private doc: jsPDF;

    private canvas: any;

    private rootView: any;

    private cardCount = 0;

    private initialized = false;

    private maxCardDimensions: {
        width: number,
        height: number
    };

    constructor(
        private pageSize = 'a4',
        private cardsPerPage = 4
    ) {

        const dimensions = this.getDimensions();
        const gridDefinition = this.getGridDefinition();

        switch (gridDefinition.orientation) {
            case PORTRAIT:
                this.doc = new jsPDF('portrait','mm',[dimensions.width, dimensions.height]);

                this.maxCardDimensions = {
                    width: dimensions.width / gridDefinition.columns,
                    height: dimensions.height / gridDefinition.rows
                };
                break;

            case LANDSCAPE:
            default:
                this.doc = new jsPDF('landscape','mm',[dimensions.height, dimensions.width]);

                this.maxCardDimensions = {
                    width: dimensions.height / gridDefinition.columns,
                    height: dimensions.width / gridDefinition.rows
                };
                break;
        }


        this.canvas = document.createElement('canvas');
        this.canvas.style.backgroundColor = "#ffffff";

        //document.body.appendChild(this.canvas);
    }

    public async initialize() {
        if (this.initialized) {
            return;
        }

        let canvasWidth = 1050 * 2;
        let canvasHeight = 1485 * 2;

        this.rootView = new RootView({
            canvas: this.canvas,
            width: canvasWidth * this.getGridDefinition().canvasZoom,
            height: canvasHeight * this.getGridDefinition().canvasZoom
        });

        return new Promise<void>(
            (resolve, reject) => {
                // wait 2 seconds to make sure fonts are loaded.
                setTimeout(() => {
                    resolve();
                }, 2000);
            }
        )
    }

    private getDimensions() {
        switch (this.pageSize) {
            case 'a4':
            default:
                return {
                    width: 210,
                    height: 297,
                };
        }
    }

    private getGridDefinition() {
        switch (this.cardsPerPage) {
            case 1:
                return {
                    orientation: PORTRAIT,
                    rows: 1,
                    columns: 1,
                    canvasZoom: 3
                };

            case 2:
                return {
                    orientation: LANDSCAPE,
                    rows: 1,
                    columns: 2,
                    canvasZoom: 2
                };

            case 3:
                return {
                    orientation: LANDSCAPE,
                    rows: 1,
                    columns: 3,
                    canvasZoom: 2
                };

            case 4:
            default:
                return {
                    orientation: PORTRAIT,
                    rows: 2,
                    columns: 2,
                    canvasZoom: 1
                };
        }
    }

    private getNextCardPosition() {
        const indexOnPage = this.cardCount % this.cardsPerPage;
        if (indexOnPage === 0 && this.cardCount > 0) {
            this.newPage();
        }

        let row = 0;
        let column = 0;

        if (this.getGridDefinition().rows > 1) {
            row = Math.floor(indexOnPage / this.getGridDefinition().rows);
            column = indexOnPage % this.getGridDefinition().rows;
        } else {
            row = 0;
            column = indexOnPage;
        }

        this.cardCount ++;

        return {
            x: Math.round(this.maxCardDimensions.width * column),
            y: Math.round(this.maxCardDimensions.height * row),
            width: Math.round(this.maxCardDimensions.width),
            height: Math.round(this.maxCardDimensions.height)
        }
    }

    private newPage() {
        this.doc.addPage();
    }

    public printCard(card: Card): Promise<void>
    {
        /*
        let cardHtml = '<table>';

        for (let r = 0; r < card.height; r ++) {
            cardHtml += '<tr>';
            for (let c = 0; c < card.width; c ++) {
                cardHtml += '<td>';

                if (card.isFree(c, r)) {
                    cardHtml += 'QW';
                } else {
                    cardHtml += card.getCell(c, r).text;
                }

                cardHtml += '</td>';
            }
            cardHtml += '</tr>';
        }

        cardHtml += '</table>';

        let cardDiv = document.createElement('div');
        cardDiv.className = 'card';
        cardDiv.innerHTML = cardHtml;

        this.container.appendChild(cardDiv);*/

        console.log('Printing card');
        return new Promise(
            (resolve, reject) => {

                const view = new CardView(card);
                this.rootView.setView(view);

                var waitForFrames = 1;
                var method = () => {

                    console.log('Tick!');
                    if (waitForFrames > 0) {
                        console.log('Waiting for ' + waitForFrames + ' frames');

                        waitForFrames --;
                        return;
                    }

                    console.log('Drawing card');

                    const position = this.getNextCardPosition();

                    

                    var png = this.canvas.toDataURL("image/jpeg");
                    this.doc.addImage(png, 'png', position.x, position.y, position.width, position.height);

                    //view.el.removeEventListener('tick', method);
                    createjs.Ticker.removeEventListener('tick', method);

                    console.log('Done drawing card');
                    resolve();
                };

                //view.el.addEventListener('tick', method);
                createjs.Ticker.addEventListener('tick', method);

            }
        );
    }

    public async printCards(cards: Card[])
    {
        for (let i = 0; i < cards.length; i ++) {
            await this.printCard(cards[i]);
        }
    }

    public async write()
    {
        //let emojiFontContent = (await axios.create().get(emojiFont)).data;
        await this.doc.save('cards.pdf', { returnPromise: true });
    }
}

import {BaseView} from "../easelbone/base.view";
import {Theme} from "../../utils/theme.util";
import {Card} from "../../models/card.model";
const easelbone = require('easelbone');
const createjs = require('createjs');

/**
 *
 */
export class CardView extends BaseView
{
    private checkSymbols: any = [];

    /**
     * @param card
     */
    constructor(
        card: Card
    ) {
        super({
            card: card
        });
    }

    /**
     * @param options
     */
    public initialize(options: any) {

        const font = 'Graduate';

        const view = options.view ?? 'CardPrint';

        const screen = Theme.get(view);
        this.setScreen(screen);

        const card = options.card;

        this.findPlaceholders('title').forEach(
            (placeholder: any) => {

                const bigText = new easelbone.EaselJS.BigText('B I N G O', font, 'black');
                placeholder.addChild(bigText);

            });

        this.findPlaceholders('card').forEach(
            (placeholder: any) => {

                const gridView = new easelbone.Controls.GridFill(placeholder);
                gridView.setPadding(0.1, 0.1);

                for (let i = 0; i < card.width; i ++) {
                    for (let j = 0; j < card.height; j ++) {

                        this.drawGridCell(card, gridView, i, j);
                    }
                }
            }
        );

        this.findPlaceholders('serial').forEach(
            (placeholder: any) => {

                const bigText = new easelbone.EaselJS.BigText('Generated with QuizWitz.com - Card ' + card.token, 'sans-serif', 'black', 'center');
                placeholder.addChild(bigText);
            }
        );
    }

    private drawGridCell(card: Card, gridView: any, i: number, j: number)
    {
        const font = 'Graduate';

        let bigText;
        if (card.isFree(i, j)) {

            const freeSpace = Theme.get('FreeSpace');
            bigText = new easelbone.EaselJS.Fill(freeSpace);

            //bigText = new easelbone.EaselJS.BigText('FREE', font, 'black');
        } else {
            bigText = new easelbone.EaselJS.BigText(
                card.getCell(i, j).text,
                font,

                'black'
            );
            //bigText.debug = true;
        }

        if (card.isChecked(i, j)) {
            let cross = Theme.get('Cross');
            cross.alpha = 0.8;
            gridView.float(i, j, new easelbone.EaselJS.Fill(cross));
            gridView.redrawGrid();
        }

        gridView.set(i, j, bigText);
        gridView.addCellEventListener(i, j, 'click', (event: any) => {

            // is checked?
            if (card.isChecked(i, j)) {
                card.markUnchecked(i, j);
                gridView.clearFloats(i, j);
                gridView.redrawGrid();
                return;
            }

            card.markChecked(i, j);

            let cross = Theme.get('Cross');
            cross.alpha = 0.8;
            gridView.float(i, j, new easelbone.EaselJS.Fill(cross));
            gridView.redrawGrid();
        });
    }

    public render() {
        this.addCenter(this.easelScreen, 1050, 1485);
    }

    /**
     * @returns {string}
     */
    getBackground() {
        return '#ffffff';
    }

}

const easelbone = require('easelbone');

const BaseView = easelbone.Views.Base.extend({

    initialize: function() {


    }

});
export { BaseView };

import {Application} from "./application";

const application = new Application();

function parseQuery(queryString: string) {
    var query: any = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

// Read anchor tags
const anchorData = parseQuery(window.location.hash.substr(1));

if (typeof(anchorData.access_token) !== 'undefined') {
    application.setAccessToken(anchorData.access_token);
}

if (typeof(anchorData.publisherId) !== 'undefined') {
    application.setPublisherId(anchorData.publisherId);
}

//application.printCards();
if (window.parent) {

    const MSG_TARGET_ORIGIN = '*';
    window.addEventListener('message', async (message) => {

        if (typeof (message.data.action) === 'undefined') {
            return;
        }

        switch (message.data.action) {

            case 'cards:generate':

                const options = message.data.options;

                const bingoId = options.bingo ? parseInt(options.bingo) : null;
                const amount = options.amount ? parseInt(options.amount) : null;
                const paperSize = options.paperSize || null;
                const cardsPerPage = options.cardsPerPage ? parseInt(options.cardsPerPage) : null;
                const shufflePerPage = options.shufflePerPage || true;

                try {
                    await application.printCards(bingoId, {
                        amount: amount,
                        paperSize: paperSize,
                        cardsPerPage: cardsPerPage,
                        shufflePerPage: shufflePerPage
                    });

                    //console.log(message);

                    window.parent.postMessage({action: 'cards:generated'}, MSG_TARGET_ORIGIN);
                } catch (e) {
                    window.parent.postMessage({action: 'app:error', error: { message: e.toString() }}, MSG_TARGET_ORIGIN);
                }

                break;

        }

    });

    window.parent.postMessage({action: 'app:initialized'}, MSG_TARGET_ORIGIN);
} else {
    console.log('window.parent not found');
}

// VERY simple router
var path = window.location.pathname;
var parts = path.split('/');

if (parts[1] === 'b') {
    var bingoId = parts[2];
    var secret = parts[3];

    application.publicSessionCard(bingoId, secret);
}

//application.showCard(407950);
